import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../types';
import { useAuthStore } from '../../store/auth';

interface DailyProductionFormProps {
  project: Project;
  date: string;
  onSave: (entries: { itemId: string; quantity: number }[]) => Promise<void>;
  readOnly?: boolean;
  loading?: boolean;
}

export default function DailyProductionForm({ 
  project, 
  date, 
  onSave, 
  readOnly,
  loading 
}: DailyProductionFormProps) {
  const [quantities, setQuantities] = useState<Record<string, number>>({});
  const { t } = useTranslation();
  const { hasPermission } = useAuthStore();

  const handleQuantityChange = (itemId: string, quantity: number) => {
    if (readOnly) return;
    setQuantities(prev => ({
      ...prev,
      [itemId]: quantity
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (readOnly) return;

    const entries = Object.entries(quantities)
      .filter(([_, quantity]) => quantity > 0)
      .map(([itemId, quantity]) => ({
        itemId,
        quantity
      }));

    onSave(entries);
    setQuantities({});
    
  };

  if (!project.items?.length) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 text-center">
        <p className="text-gray-500">{t('common.noData')}</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-bold text-gray-800 mb-6">
        {t('dailyProduction.title')} - {project.name}
      </h3>
      
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          {project.items.map(item => {
            const remainingQuantity = item.targetQuantity - item.completedQuantity;
            const progress = (item.completedQuantity / item.targetQuantity) * 100;
            
            return (
              <div key={item.itemId} className="border rounded-lg p-4">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h4 className="font-medium text-gray-900">{item.name}</h4>
                    <p className="text-sm text-gray-500">
                      {t('dailyProduction.remainingQuantity')}: {remainingQuantity} {item.unit}
                    </p>
                  </div>
                  <div className="text-sm text-gray-500">
                    {t('dailyProduction.completedQuantity')}: {item.completedQuantity} {item.unit}
                  </div>
                </div>
                
                <div className="flex items-center space-x-4 space-x-reverse">
                  <input
                    type="number"
                    className="w-32 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222] disabled:bg-gray-100"
                    placeholder={t('dailyProduction.quantity')}
                    value={quantities[item.itemId] || ''}
                    onChange={(e) => handleQuantityChange(item.itemId, parseFloat(e.target.value))}
                    min="0"
                    max={remainingQuantity}
                    disabled={readOnly}
                    step='any'
                  />
                  <span className="text-gray-600">{item.unit}</span>


                  {hasPermission('view_financial') && (
                    <div className="text-gray-600">
                      {t('dailyProduction.totalValue')}: {((quantities[item.itemId] || 0) * item.unitPrice).toLocaleString()} {t('common.currency')}
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <div className="flex justify-between text-sm text-gray-600 mb-1">
                    <span>{t('projects.progress')}</span>
                    <span>{Math.round(progress)}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-[#c62222] rounded-full h-2 transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

      {!readOnly && (
        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            className="px-6 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222] disabled:opacity-50"
            disabled={loading}
          >
            {loading ? t('common.saving') : t('common.save')}
          </button>
        </div>
      )}
      </form>
    </div>
  );
}