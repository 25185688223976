import React, { useState, useMemo } from 'react';
import { Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../types';

interface ProjectSelectorProps {
  projects: Project[];
  selectedProjectId: string;
  onProjectSelect: (projectId: string) => void;
  date: string;
  onDateChange: (date: string) => void;
}

export default function ProjectSelector({
  projects,
  selectedProjectId,
  onProjectSelect,
  date,
  onDateChange
}: ProjectSelectorProps) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  // Filter active projects and then apply search
  const activeProjects = projects.filter(p => p.status === 'نشط');
  
  // Filter projects based on search term
  const filteredProjects = useMemo(() => {
    return activeProjects.filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.workOrder.toString().includes(searchTerm)
    );
  }, [activeProjects, searchTerm]);

  // Get selected project name
  const selectedProject = projects.find(p => p.projectId === selectedProjectId);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('dailyProduction.selectProject')}
        </label>
        <div className="relative">
          <div className="w-full px-3 py-2 border border-gray-300 rounded-md focus-within:ring-2 focus-within:ring-[#c62222] focus-within:border-transparent">
            <div className="flex items-center">
              <Search className="h-5 w-5 text-gray-400" />
              <input
                type="text"
                className="w-full px-2 focus:outline-none"
                placeholder={t('dailyProduction.chooseProject')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {selectedProjectId && !searchTerm && (
              <div className="mt-1 text-sm text-gray-700">
                {selectedProject?.name} | BO: {selectedProject?.workOrder}
              </div>
            )}
          </div>

          {/* Dropdown */}
          {(searchTerm || !selectedProjectId) && (
            <div className="absolute z-20 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
              {searchTerm && !filteredProjects.length ? (
                <div className="px-4 py-2 text-sm text-gray-500">
                  {t('common.noResults')}
                </div>
              ) : (
                filteredProjects.map(project => (
                  <button
                    key={project.projectId}
                    onClick={() => {
                      onProjectSelect(project.projectId);
                      setSearchTerm('');
                    }}
                    className={`w-full px-4 py-2 text-right hover:bg-gray-100 ${
                      selectedProjectId === project.projectId ? 'bg-gray-50' : ''
                    }`}
                  >
                    {project.name} | BO: {project.workOrder}
                  </button>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}