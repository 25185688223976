import React from 'react';
import { MapPin, Calendar, DollarSign, Edit2, Trash2, Briefcase } from 'lucide-react';
import { Project } from '../../types';
import { useAuthStore } from '../../store/auth';
import { formatCurrency } from '../../utils/reportCalculations';

interface ProjectCardProps {
  project: Project;
  onEdit?: () => void;
  onDelete?: () => void;
}

export default function ProjectCard({ project, onEdit, onDelete }: ProjectCardProps) {
  const { hasPermission } = useAuthStore();


  const progress = project.items ? (
    project.items.reduce((acc, item) => 
      acc + ((item.completedQuantity / item.targetQuantity) * 100), 0
    ) / project.items.length 
   ) : 0;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'نشط':
        return 'bg-green-100 text-green-800';
      case 'متوقف':
        return 'bg-yellow-100 text-yellow-800';
      case 'مكتمل':
        return 'bg-green text-white';
      case 'ملغي':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h4 className="text-lg font-bold text-gray-800 mb-2">{project.name}</h4>
          <div className="flex items-center text-gray-600">
            <Briefcase className="h-4 w-4 ml-2" />
            <span className="text-sm">BO: {project.workOrder}</span>
          </div>
          <div className="flex items-center text-gray-600">
            <MapPin className="h-4 w-4 ml-2" />
            <span className="text-sm">{project.location}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2 space-x-reverse">
          {hasPermission('edit_projects') && onEdit && (
            <button
              onClick={onEdit}
              className="p-1 text-gray-500 hover:text-gray-700"
            >
              <Edit2 className="h-4 w-4" />
            </button>
          )}
          {hasPermission('delete_projects') && onDelete && (
            <button
              onClick={onDelete}
              className="p-1 text-red-500 hover:text-red-700"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <span className={`px-3 py-1 rounded-full text-sm ${getStatusColor(project.status)}`}>
          {project.status}
        </span>
      </div>

      <div className="mb-4">
        <div className="flex justify-between text-sm text-gray-600 mb-2">
          <span>نسبة الإنجاز</span>
          <span>{(progress || 0).toFixed(0)}%</span>
          </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-[#c62222] rounded-full h-2"
            style={{ width: `${progress || 0}%` }}
            />
        </div>
      </div>

      <div className="flex justify-between text-sm text-gray-600">
          {hasPermission('view_financial') && (
            <div className="flex items-center">

            <DollarSign className="h-4 w-4 ml-1" />
            <span>{formatCurrency(project.budget)}</span>
            </div>
          )}
        <div className="flex items-center">
          <Calendar className="h-4 w-4 ml-1" />
          <span>{new Date(project.endDate).toLocaleDateString('en-US')}</span>
        </div>
      </div>
    </div>
  );
}