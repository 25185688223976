import React, { useEffect } from 'react';
import { ReportPeriod, ProductionReport as IProductionReport } from '../../types';
import { useReportsStore } from '../../store/reports';
import { Package2, Target, BarChart4 } from 'lucide-react';

interface ProductionReportProps {
  projectId: string;
  period: ReportPeriod;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

export default function ProductionReport({ projectId, period, dateRange }: ProductionReportProps) {
  const { getProductionReport, productionReport, loading, error } = useReportsStore();

  useEffect(() => {
    if (projectId && dateRange.startDate && dateRange.endDate) {
      getProductionReport(projectId, period, dateRange);
    }
  }, [projectId, period, dateRange.startDate, dateRange.endDate]);

  if (loading) return <div className="text-center p-8">جاري تحميل البيانات...</div>;
  if (error) return <div className="text-center p-8 text-red-600">{error}</div>;
  if (!productionReport?.data) return null; // Add null check for data specifically

  // Format helpers remain the same
  const formatNumber = (value: number | undefined | null): string => {
    if (value === undefined || value === null) return '0';
    return value.toLocaleString();
  };
  
  const formatPercent = (value: number | undefined | null): string => {
    if (value === undefined || value === null) return '0';
    return value.toFixed(1);
  };


  const totalPeriodQuantity = productionReport?.totalPeriodQuantity ?? 0;
  const totalProgress = productionReport?.totalProgress ?? 0;
  const completedItems = productionReport?.completedItems ?? 0;
  const totalItems = productionReport?.totalItems ?? 0;
  const reportData = productionReport?.data ?? [];


  return (
    <div className="space-y-6">
      {/* Production Summary */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">الكمية المنفذة في الفترة</p>
              <p className="text-2xl font-bold text-gray-800">
                {formatNumber(totalPeriodQuantity)}
              </p>
            </div>
            <div className="bg-[#c62222] p-3 rounded-full">
              <Package2 className="h-6 w-6 text-white" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">نسبة الإنجاز الكلية</p>
              <p className="text-2xl font-bold text-gray-800">
                {formatPercent(productionReport?.totalProgress)}%
              </p>
            </div>
            <div className="bg-green-100 p-3 rounded-full">
              <Target className="h-6 w-6 text-green-600" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">البنود المكتملة</p>
              <p className="text-2xl font-bold text-gray-800">
               {formatNumber(productionReport?.totalItems)} / {formatNumber(productionReport?.completedItems)}
              </p>
            </div>
            <div className="bg-blue-100 p-3 rounded-full">
              <BarChart4 className="h-6 w-6 text-blue-600" />
            </div>
          </div>
        </div>
      </div>

      {/* Production Details Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">البند</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الوحدة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الكمية في الفترة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الكمية المستهدفة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الكمية المنجزة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">نسبة الإنجاز</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">

            {reportData.map((item) => (
                <tr key={item.itemId}>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.unit}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {formatNumber(item.periodQuantity)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {formatNumber(item.targetQuantity)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {formatNumber(item.completedQuantity)}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="w-24 bg-gray-200 rounded-full h-2 ml-2">
                        <div
                          className="bg-[#c62222] rounded-full h-2"
                          style={{ width: `${item.progress ?? 0}%` }}
                        />
                      </div>
                      <span className="text-sm text-gray-600">
                        {formatPercent(item.progress)}%
                      </span>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}