import React, { useState } from 'react';
import { History, Search, Download } from 'lucide-react';
import { SystemLog } from '../../../types';

const mockLogs: SystemLog[] = [
  {
    id: '1',
    timestamp: '2024-03-15T14:30:00',
    action: 'تسجيل دخول',
    userId: 'user123',
    details: 'تم تسجيل الدخول من متصفح Chrome'
  },
  {
    id: '2',
    timestamp: '2024-03-15T14:25:00',
    action: 'تحديث مشروع',
    userId: 'user456',
    details: 'تم تحديث حالة المشروع "مشروع تطوير الواجهة البحرية"'
  },
  {
    id: '3',
    timestamp: '2024-03-15T14:20:00',
    action: 'إضافة مستخدم',
    userId: 'admin789',
    details: 'تم إضافة مستخدم جديد: محمد أحمد'
  }
];

export default function SystemLogs() {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterDate, setFilterDate] = useState(new Date().toISOString().split('T')[0]);

  const filteredLogs = mockLogs.filter(log => 
    log.action.includes(searchQuery) || 
    log.details.includes(searchQuery)
  );

  const handleExportLogs = () => {
    // Handle logs export logic
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-bold text-gray-800">سجلات النظام</h3>
        <button
          onClick={handleExportLogs}
          className="flex items-center px-4 py-2 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          <Download className="h-4 w-4 ml-2" />
          تصدير السجلات
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6 border-b">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="البحث في السجلات..."
                className="w-full px-4 py-2 pr-12 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            </div>

            <div>
              <input
                type="date"
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">التوقيت</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الإجراء</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">المستخدم</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">التفاصيل</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredLogs.map((log) => (
                <tr key={log.id}>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {new Date(log.timestamp).toLocaleString('en-US')}
                  </td>
                  <td className="px-6 py-4">
                    <span className="px-3 py-1 text-sm rounded-full bg-[#c62222] text-white">
                      {log.action}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">{log.userId}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{log.details}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredLogs.length === 0 && (
          <div className="p-6 text-center text-gray-500">
            لا توجد سجلات متطابقة مع معايير البحث
          </div>
        )}
      </div>
    </div>
  );
}