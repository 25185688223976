// utils/exportUtils.ts
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { ProductionReport, FinancialReport, TimelineReport, ReportType } from '../types';
import ExcelJS from 'exceljs';
import { Alignment, Font, Fill, Border } from 'exceljs';

type Report = ProductionReport | FinancialReport | TimelineReport;

interface ExportOptions {
  title: string;
  subtitle?: string;
  showDate?: boolean;
  orientation?: 'portrait' | 'landscape';
}

interface jsPDFWithAutoTable extends jsPDF {
  lastAutoTable?: { finalY: number };
  autoTable: (options: any) => void;
}


// Style constants
const STYLE = {
  colors: {
    primary: '#c80808',
    secondary: '#2c3e50',
    header: '#c80808',
    border: '#e2e8f0',
  },
  excel: {
    headerFill: 'C80808',
    headerFont: 'FFFFFF',
    summaryFill: 'F8FAFC',
    borderColor: '64748B',
  }
};

const formatCurrency = (value: number): string => 
  `${value.toLocaleString('en-US', { maximumFractionDigits: 2 })} ريال`;

const formatPercentage = (value: number): string => 
  `${value.toFixed(1)}%`;

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const formatValue = (value: any, type: string): string => {
  if (value === null || value === undefined) return '';

  if (typeof value === 'number') {
    if (type.includes('price') || type.includes('value') || type.includes('budget')) {
      return formatCurrency(value);
    }
    if (type.includes('progress') || type.includes('Percentage')) {
      return formatPercentage(value);
    }
    return value.toLocaleString('en-US');
  }
  
  if (type === 'isDelayed') {
    return value ? 'متأخر' : 'في الموعد';
  }

  return String(value);
};

const getColumns = (reportType: ReportType) => {
  switch (reportType) {
    case 'production':
      return [
        { key: 'name', header: 'البند' },
        { key: 'unit', header: 'الوحدة المستخدمة' },
        { key: 'periodQuantity', header: 'الكمية في الفترة' },
        { key: 'completedQuantity', header: 'الكمية المنجزة' },
        { key: 'targetQuantity', header: 'الكمية المستهدفة' },

        { key: 'progress', header: 'نسبة الإنجاز'}
      ];
    case 'financial':
      return [
        { key: 'name', header: 'البند' },
        { key: 'unit', header: 'الوحدة المستخدمة' },
        { key: 'periodQuantity', header: 'الكمية في الفترة' },
        { key: 'unitPrice', header: 'سعر الوحدة' },
        { key: 'value', header: 'القيمة المحققة' },
        { key: 'plannedValue', header: 'القيمة المخططة' },
        { key: 'progress', header: 'نسبة الإنجاز' }
      ];
    case 'timeline':
      return [
        { key: 'name', header: 'البند' },
        { key: 'completedQuantity', header: 'الكمية المنجزة' },
        { key: 'targetQuantity', header: 'الكمية المستهدفة' },
        { key: 'progress', header: 'نسبة الإنجاز' },
        { key: 'expectedProgress', header: 'التقدم المتوقع' },
        { key: 'isDelayed', header: 'الحالة' }
      ];
  }
};

const getSummaryData = (report: Report) => {
  switch (report.type) {
    case 'production':
      return [
        ['نسبة الإنجاز الكلية', formatPercentage(report.totalProgress)],
        ['نسبة الإنجاز في الفترة', formatPercentage(report.periodProgress)],
        ['الكمية المنفذة في الفترة', formatValue(report.totalPeriodQuantity, 'quantity')],
        ['البنود المكتملة', `${report.completedItems}/${report.totalItems}`]
      ];
    case 'financial':
      return [
        ['نسبة الإنجاز الكلية', formatPercentage(report.totalProgress)],
        ['نسبة الإنجاز في الفترة', formatPercentage(report.periodProgress)],
        ['القيمة المحققة في الفترة', formatCurrency(report.periodTotalValue)],
        ['القيمة المحققة الإجمالية', formatCurrency(report.totalValue)],
        ['القيمة المخططة', formatCurrency(report.totalPlannedValue)]
      ];
    case 'timeline':
      return [
        ['نسبة الإنجاز الكلية', formatPercentage(report.totalProgress)],
        ['التقدم المتوقع', formatPercentage(report.expectedProgress)],
        ['الأيام المتبقية', report.daysRemaining.toString()],
        ['حالة المشروع', report.isDelayed ? 'متأخر' : 'في الموعد'],
        ['الانحراف عن الخطة', formatPercentage(report.scheduleVariance)]
      ];
  }
};



const configurePDF = async (doc: jsPDFWithAutoTable) => {
  return new Promise<void>((resolve) => {
    // Load Regular font
    fetch('/fonts/NotoNaskhArabic-Regular.ttf')
      .then(response => response.arrayBuffer())
      .then(buffer => {
        // Convert ArrayBuffer to base64
        const base64 = btoa(
          new Uint8Array(buffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        doc.addFileToVFS('Cairo-Regular.ttf', base64);
        doc.addFont('Cairo-Regular.ttf', 'Cairo', 'normal');
        
        // Load Bold font
        return fetch('/fonts/NotoNaskhArabic-Regular.ttf');
      })
      .then(response => response.arrayBuffer())
      .then(buffer => {
        // Convert ArrayBuffer to base64
        const base64 = btoa(
          new Uint8Array(buffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        doc.addFileToVFS('NotoNaskhArabic-Regular.ttf', base64);
        doc.addFont('NotoNaskhArabic-Regular.ttf', 'Cairo', 'bold');
        
        // Set default font and RTL
        doc.setFont('Cairo');
        // doc.setR2L(true);
        resolve();
      })
      .catch(error => {
        console.error('Error loading fonts:', error);
        // Resolve anyway to allow PDF generation without custom fonts
        resolve();
      });
  });
};

export const exportToPDF = async (
  report: Report,
  dateRange: { startDate: string; endDate: string },
  options: ExportOptions
) => {
  try {
    const doc = new jsPDF({
      orientation: options.orientation || 'portrait',
      unit: 'pt',
      format: 'a4'
    }) as jsPDFWithAutoTable;
    
    // Set RTL and add fonts
    await configurePDF(doc);

    // Add logo
    const img = new Image();
    img.src = '/logo.png';
    await new Promise((resolve) => {
      img.onload = () => {
        const imgWidth = 100;
        const imgHeight = (img.height * imgWidth) / img.width;
        doc.addImage(img, 'PNG', 40, 20, imgWidth, imgHeight);
        resolve(true);
      };
    });

    // Add header
    doc.setTextColor(STYLE.colors.primary);
    doc.setFontSize(22);
    doc.text('برنامج ميتل لادارة المشاريع', doc.internal.pageSize.width - 40, 40, { align: 'right' });

    // Add report info
    doc.setTextColor(0);
    doc.setFontSize(12);
    const reportInfo = [
      options.title,
      `المشروع: ${report.projectName}`,
      `امر العمل: ${report.workOrder}`,
      `تاريخ التقرير: ${formatDate(new Date().toISOString())}`,
      `الفترة: ${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`
    ];
    doc.text(reportInfo, doc.internal.pageSize.width - 40, 80, { 
      align: 'right',
      lineHeightFactor: 1.5
    });

    // Get columns and prepare data
    const columns = getColumns(report.type);
    const headers = columns.map(col => col.header).reverse(); // Reverse headers for RTL
    const filteredData = report.data.filter((item: any) => item.periodQuantity > 0);
    const data = filteredData.map((item: any) => 
      columns.map(col => formatValue(item[col.key], col.key)).reverse() // Reverse data for RTL
    );

    // Common table styles with improved text handling
    const commonStyles = {
      font: 'Cairo',
      fontSize: 10,
      cellPadding: { top: 8, bottom: 8, left: 5, right: 5 },
      halign: 'right',
      direction: 'rtl',
      minCellHeight: 40,
      cellWidth: 'auto',
      overflow: 'linebreak',
      valign: 'middle'
    };

    // Add main data table first
    (doc as any).autoTable({
      head: [headers],
      body: data,
      startY: 180,
      theme: 'grid',
      styles: commonStyles,
      headStyles: {
        ...commonStyles,
        fillColor: [200, 8, 8],
        textColor: 255,
        fontStyle: 'bold',
        fontSize: 11,
        minCellHeight: 45, // Slightly higher for headers
        valign: 'middle',
        cellPadding: { top: 10, bottom: 10, left: 5, right: 5 }
      },
      columnStyles: headers.reduce((acc, _, index) => ({
        ...acc,
        [index]: {
          ...commonStyles,
          minCellWidth: 70 // Ensure minimum width for all columns
        }
      }), {}),
      margin: { right: 40, left: 40 },
      tableWidth: 'auto',
      didParseCell: function(data: any) {
        // Increase height if content is wrapped
        const cell = data.cell;
        const text = cell.text;
        if (Array.isArray(text) && text.length > 1) {
          cell.styles.minCellHeight = cell.styles.minCellHeight + (text.length - 1) * 12;
        }
      }
    });

    // Add summary table below main table
    const summaryData = getSummaryData(report);
    const reversedSummaryData = summaryData.map(row => [row[1], row[0]]);
    (doc as any).autoTable({
      head: [[ 'القيمة','البيان' ]],
      body: reversedSummaryData,
      startY: (doc.lastAutoTable?.finalY || 0) + 30,
      theme: 'grid',
      styles: commonStyles,
      headStyles: {
        ...commonStyles,
        fillColor: [24, 164, 145],
        textColor: 255,
        fontStyle: 'bold',
        fontSize: 11,
        minCellHeight: 45,
        valign: 'middle',
        cellPadding: { top: 10, bottom: 10, left: 5, right: 5 }
      },
      columnStyles: {
        0: { ...commonStyles, minCellWidth: 200 },  // Label column
        1: { ...commonStyles, minCellWidth: 120 }   // Value column
      },
      margin: { right: 40, left: 40 },
      tableWidth: 'auto'
    });

    // Add footer with page numbers
    const pageCount = (doc as any).internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `صفحة ${i} من ${pageCount}`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 20,
        { align: 'center' }
      );
    }

    // Save the PDF
    const fileName = `${report.projectName}-${report.type}-${dateRange.startDate}.pdf`;
    doc.save(fileName);
    return fileName;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};




export const exportToExcel = async (
  report: Report,
  dateRange: { startDate: string; endDate: string },
  options: ExportOptions
) => {
  try {



    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('التقرير', {
      views: [{ rightToLeft: true }]
    });

    // Add Logo using base64
    try {
      const response = await fetch('/logo.png');
      const blob = await response.blob();
      const reader = new FileReader();
      
      const base64 = await new Promise<string>((resolve) => {
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      const logoId = workbook.addImage({
        base64,
        extension: 'png',
      });

      worksheet.addImage(logoId, {
        tl: { col:6, row: 0 },
        ext: { width: 100, height: 100 }
      });
    } catch (error) {
      console.error('Error loading logo:', error);
    }

    worksheet.columns = [
      { width: 35 }, 
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
    ];

    // Define styles
    const styles: Record<string, Partial<ExcelJS.Style>> = {
      header: {
        font: {
          name: 'Arial',
          size: 16,
          bold: true,
          color: { argb: 'FFFFFF' }
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C80808' }
        },
        alignment: {
          horizontal: 'right',
          vertical: 'middle'
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      },
      subHeader: {
        font: {
          name: 'Arial',
          size: 14,
          bold: true
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F8F9FA' } // Change to a light color to make it visible
        },
        alignment: {
          horizontal: 'right',
          vertical: 'middle'
        }
      },
      normal: {
        font: {
          name: 'Arial',
          size: 11
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF' }  // Ensure it's not white for summary data
        },
        alignment: {
          horizontal: 'right',
          vertical: 'middle'
        }
      }
    };

    // Add title section
    const titleRow = worksheet.addRow(['برنامج ميتل لادارة المشاريع']);
    titleRow.height = 30;
    Object.assign(titleRow.getCell(1), styles.header);
    worksheet.mergeCells(`A${titleRow.number}:F${titleRow.number}`);

    worksheet.addRow([]); // Empty row

    // Add report info section
    const reportInfoRows = [
      [options.title],
      ['معلومات المشروع'],
      [`اسم المشروع: ${report.projectName}`],
      [`امر العمل: ${report.workOrder}`],
      [`تاريخ التقرير: ${formatDate(new Date().toISOString())}`],
      [`الفترة: ${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`]
    ];

    reportInfoRows.forEach((rowData, index) => {
      const row = worksheet.addRow(rowData);
      row.height = 25;
      Object.assign(row.getCell(1), index < 2 ? styles.subHeader : styles.normal);
      worksheet.mergeCells(`A${row.number}:F${row.number}`);
    });

    worksheet.addRow([]); // Empty row

    // Add summary section
    const summaryTitle = worksheet.addRow(['ملخص التقرير']);
    Object.assign(summaryTitle.getCell(1), styles.subHeader);
    worksheet.mergeCells(`A${summaryTitle.number}:F${summaryTitle.number}`);

    const summaryData = getSummaryData(report);
    if (!summaryData || summaryData.length === 0) {
      console.error('Summary data is empty or undefined.');
    }
    
    summaryData.forEach(([label, value]) => {
      const row = worksheet.addRow([label, value]);
      row.height = 25;
      
      Object.assign(row.getCell(1), styles.normal);
      Object.assign(row.getCell(2), styles.normal);
      // worksheet.mergeCells(`A${row.number}:B${row.number}`);
      // worksheet.mergeCells(`C${row.number}:D${row.number}`);
    });

    worksheet.addRow([]); // Empty row

    // Add data table
    const columns = getColumns(report.type);
    const headerRow = worksheet.addRow(columns.map(col => col.header));
    headerRow.height = 30;
    
    headerRow.eachCell((cell) => {
      Object.assign(cell, styles.header);
    });

    // Add data rows
    report.data.forEach((item) => {
      const row = worksheet.addRow(
        columns.map(col => formatValue(item[col.key as keyof typeof item], col.key))
      );
      row.height = 25;
      row.eachCell((cell) => {
        Object.assign(cell, styles.normal);
        
        // Add progress bar styling
        const value = cell.value?.toString() || '';
        if (value.includes('%')) {
          cell.alignment.wrapText;
          const progress = parseFloat(value);
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { 
              argb: progress >= 100 ? '92D050' :
                    progress >= 70 ? 'FFEB9C' :
                    'FF9B9B'
            }
          };
        }
      });
    });

    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${report.projectName}-${report.type}-${dateRange.startDate}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);

    return link.download;
  } catch (error) {
    console.error('Error generating Excel:', error);
    throw error;
  }
};