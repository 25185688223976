// ProjectSelector.tsx
import React, { useState, useMemo } from 'react';
import { Search } from 'lucide-react';
import { useProjectsStore } from '../../store/projects';

interface ProjectSelectorProps {
  selectedProjectId: string;
  onProjectSelect: (projectId: string) => void;
}

export default function ProjectSelector({
  selectedProjectId,
  onProjectSelect
}: ProjectSelectorProps) {
  const { projects } = useProjectsStore();
  const [searchTerm, setSearchTerm] = useState('');

  // Filter projects based on search term
  const filteredProjects = useMemo(() => {
    return projects.filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.workOrder.toString().includes(searchTerm)
    );
  }, [projects, searchTerm]);

  // Group filtered projects by status
  const activeProjects = filteredProjects.filter(p => p.status === 'نشط');
  const completedProjects = filteredProjects.filter(p => p.status === 'مكتمل');
  const onHoldProjects = filteredProjects.filter(p => p.status === 'متوقف');

  // Get project status label
  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'active':
        return 'المشاريع النشطة';
      case 'completed':
        return 'المشاريع المكتملة';
      case 'onHold':
        return 'المشاريع المتوقفة';
      default:
        return '';
    }
  };

  // Render project group
  const renderProjectGroup = (projectsList: typeof projects, status: string) => {
    if (!projectsList.length) return null;
    
    return (
      <div className="py-2">
        <div className="px-4 py-1 text-sm font-medium text-gray-500">
          {getStatusLabel(status)}
        </div>
        {projectsList.map(project => (
          <button
            key={project.projectId}
            onClick={() => {
              onProjectSelect(project.projectId);
              setSearchTerm('');
            }}
            className={`w-full px-4 py-2 text-right hover:bg-gray-100 ${
              selectedProjectId === project.projectId ? 'bg-gray-50' : ''
            }`}
          >
            {project.name} | BO: {project.workOrder}
          </button>
        ))}
      </div>
    );
  };

  // Get selected project name
  const selectedProject = projects.find(p => p.projectId === selectedProjectId);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="relative">
        <div className="w-full px-4 py-3 rounded-lg border border-gray-200 focus-within:ring-2 focus-within:ring-[#c62222] focus-within:border-transparent">
          <div className="flex items-center">
            <Search className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              className="w-full px-2 focus:outline-none"
              placeholder="ابحث عن مشروع..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {selectedProjectId && !searchTerm && (
            <div className="mt-1 text-sm text-gray-700">
              {selectedProject?.name} | BO: {selectedProject?.workOrder}
            </div>
          )}
        </div>

        {/* Dropdown */}
        {(searchTerm || !selectedProjectId) && (
          <div className="absolute z-20 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
            {searchTerm && !filteredProjects.length ? (
              <div className="px-4 py-2 text-sm text-gray-500">لا توجد نتائج</div>
            ) : (
              <>
                {renderProjectGroup(activeProjects, 'active')}
                {renderProjectGroup(onHoldProjects, 'onHold')}
                {renderProjectGroup(completedProjects, 'completed')}
              </>
            )}
          </div>
        )}
      </div>
      
      {/* Show total projects info */}
      <div className="mt-4 text-sm text-gray-500 flex justify-between">
        <span>المشاريع النشطة: {activeProjects.length}</span>
        <span>المشاريع المكتملة: {completedProjects.length}</span>
        <span>المشاريع المتوقفة: {onHoldProjects.length}</span>
      </div>
    </div>
  );
}