// components/reports/TimelineReport.tsx
import React, { useEffect } from 'react';
import { ReportPeriod } from '../../types';
import { useReportsStore } from '../../store/reports';
import { Clock, AlertTriangle, CheckCircle, Calendar, TrendingUp } from 'lucide-react';

interface TimelineReportProps {
  projectId: string;
  period: ReportPeriod;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

export default function TimelineReport({ projectId, period }: TimelineReportProps) {
  const { getTimelineReport, timelineReport, loading, error } = useReportsStore();

  useEffect(() => {
    if (projectId) {
      getTimelineReport(projectId, period);
    }
  }, [projectId, period]);

  if (loading) return <div className="text-center p-8">جاري تحميل البيانات...</div>;
  if (error) return <div className="text-center p-8 text-red-600">{error}</div>;
  if (!timelineReport) return null;

  const formatPercent = (value: number): string => value.toFixed(1);
  const formatDate = (date: string): string => new Date(date).toLocaleDateString('en-US');

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">الأيام المتبقية</p>
              <p className="text-2xl font-bold text-gray-800">
                {timelineReport.daysRemaining} يوم
              </p>
            </div>
            <div className="bg-blue-100 p-3 rounded-full">
              <Clock className="h-6 w-6 text-blue-600" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">الأيام المنقضية</p>
              <p className="text-2xl font-bold text-gray-800">
                {timelineReport.elapsedDays} يوم
              </p>
            </div>
            <div className="bg-purple-100 p-3 rounded-full">
              <Calendar className="h-6 w-6 text-purple-600" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">نسبة التقدم</p>
              <p className="text-2xl font-bold text-gray-800">
                {formatPercent(timelineReport.totalProgress)}%
              </p>
            </div>
            <div className={`p-3 rounded-full ${timelineReport.isDelayed ? 'bg-red-100' : 'bg-green-100'}`}>
              {timelineReport.isDelayed ? (
                <AlertTriangle className="h-6 w-6 text-red-600" />
              ) : (
                <CheckCircle className="h-6 w-6 text-green-600" />
              )}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">المهام المتأخرة</p>
              <p className="text-2xl font-bold text-gray-800">
                {timelineReport.delayedTasks.length} / {timelineReport.totalItems}
              </p>
            </div>
            <div className="bg-yellow-100 p-3 rounded-full">
              <AlertTriangle className="h-6 w-6 text-yellow-600" />
            </div>
          </div>
        </div>
      </div>

      {/* Timeline Comparison */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-bold text-gray-800 mb-6">مقارنة الجداول الزمنية</h3>
        <div className="space-y-6">
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">الجدول الزمني الأساسي</h4>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <span className="text-sm text-gray-500">تاريخ البداية</span>
                <p className="font-medium">{formatDate(timelineReport.baselineStartDate)}</p>
              </div>
              <div>
                <span className="text-sm text-gray-500">تاريخ النهاية</span>
                <p className="font-medium">{formatDate(timelineReport.baselineEndDate)}</p>
              </div>
            </div>
            <div className="mt-2">
              <span className="text-sm text-gray-500">التقدم المتوقع</span>
              <p className="font-medium">{formatPercent(timelineReport.baselineProgress)}%</p>
            </div>
          </div>

          <div className="pt-4 border-t">
            <h4 className="text-sm font-medium text-gray-700 mb-2">الجدول الزمني الحالي</h4>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <span className="text-sm text-gray-500">تاريخ البداية</span>
                <p className="font-medium">{formatDate(timelineReport.currentStartDate)}</p>
              </div>
              <div>
                <span className="text-sm text-gray-500">تاريخ النهاية</span>
                <p className="font-medium">{formatDate(timelineReport.currentEndDate)}</p>
              </div>
            </div>
            <div className="mt-2">
              <span className="text-sm text-gray-500">التقدم الفعلي</span>
              <p className="font-medium">{formatPercent(timelineReport.currentProgress)}%</p>
            </div>
          </div>
        </div>
      </div>

      {/* Progress Comparison */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-bold text-gray-800 mb-6">مقارنة التقدم</h3>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between text-sm mb-2">
              <span>التقدم المخطط (Baseline)</span>
              <span>{formatPercent(timelineReport.baselineProgress)}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-500 rounded-full h-2"
                style={{ width: `${timelineReport.baselineProgress}%` }}
              />
            </div>
          </div>

          <div>
            <div className="flex justify-between text-sm mb-2">
              <span>التقدم الفعلي</span>
              <span>{formatPercent(timelineReport.currentProgress)}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`rounded-full h-2 ${
                  timelineReport.scheduleVariance >= 0 ? 'bg-green-500' : 'bg-red-500'
                }`}
                style={{ width: `${timelineReport.currentProgress}%` }}
              />
            </div>
          </div>

          <div className="pt-4 border-t">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">الانحراف عن الخطة</span>
              <span className={`text-sm font-medium ${
                timelineReport.scheduleVariance >= 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {Math.abs(timelineReport.scheduleVariance).toFixed(1)}%
                {timelineReport.scheduleVariance >= 0 ? ' متقدم' : ' متأخر'}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Items Progress Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h3 className="text-lg font-bold text-gray-800">تقدم البنود</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">البند</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الكمية المنجزة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الكمية المستهدفة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">نسبة الإنجاز</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الحالة</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {timelineReport.data.map((item) => (
                <tr key={item.itemId}>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.completedQuantity}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.targetQuantity}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="w-24 bg-gray-200 rounded-full h-2 ml-2">
                        <div
                          className={`rounded-full h-2 ${item.isDelayed ? 'bg-red-500' : 'bg-green-500'}`}
                          style={{ width: `${item.progress}%` }}
                        />
                      </div>
                      <span className="text-sm text-gray-600">{formatPercent(item.progress)}%</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      item.isDelayed
                        ? 'bg-red-100 text-red-800'
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {item.isDelayed ? 'متأخر' : 'في الموعد'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}